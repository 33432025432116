import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Thumbnail from "../../Components/blog/Thumbnail/Thumbnail";
import { BlogContent, useBlogs } from "../../Context/BlogContext";
import { ReactComponent as ShareIcon } from "../../image/shareIcon.svg";
import { ReactComponent as LikeIcon } from "../../image/likeIcon.svg";
import { ReactComponent as LinkIcon } from "../../image/link.svg";
import styles from "./Article.module.scss";
import List from "../../Components/blog/List/List";
import Head from "../../Common/Head/Head";
import ScrollTop from "../../Components/ScrollTop/ScrollTop";
import Banner from "../../Components/Banner/Banner";
import { useGA4 } from "../../Context/GA4Context";

export default function Article() {
  const [contents, setContents] = useState({} as BlogContent);
  const [tagList, setTagList] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isLikeCLick, setIsLikeClick] = useState<any>("");
  const location = window.location;
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { onEventClick } = useGA4();
  const params = useParams();
  const { getData, clickLike, subtracylickLike, shareClick } = useBlogs();

  const onShareClick = () => {
    navigator.clipboard
      .writeText(location.href)
      .then((res) => {
        shareClick(contents.id);
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      })
      .catch(console.error);
  };
  useEffect(() => {
    if (t("transLn") === "ko" && !contents) {
      navigate("/");
    }
  }, [t, contents, navigate]);
  const onLikeClick = () => {
    if (!localStorage.getItem(`liked${contents.id}`)) {
      localStorage.setItem(`liked${contents.id}`, JSON.stringify(true));
      setIsLikeClick(localStorage.getItem(`liked${contents.id}`));
      clickLike(contents.id);
    } else {
      const like = localStorage.getItem(`liked${contents.id}`);
      localStorage.setItem(
        `liked${contents.id}`,
        like === "true" ? JSON.stringify(false) : JSON.stringify(true)
      );
      setIsLikeClick(localStorage.getItem(`liked${contents.id}`));
      if (like === "true") {
        subtracylickLike(contents.id);
      } else if (like === "false") {
        clickLike(contents.id);
      }
    }
  };

  const onTagClick = (tag: string) => {
    onEventClick(tag, "tag_click");
    navigate(`/tag?q=${tag}`, { state: tag });
  };

  useEffect((): any => {
    return async () => {
      if (
        document
          ?.getElementsByClassName(styles.contents)[0]
          ?.querySelectorAll("p")
      ) {
        const pList: any = document
          ?.getElementsByClassName(styles.contents)[0]
          ?.querySelectorAll("p");
        const divList: any = document
          ?.getElementsByClassName(styles.contents)[0]
          ?.querySelectorAll("div");
        for (const list of pList) {
          let images = await list?.querySelectorAll("img");
          for (const item of images) {
            item?.classList.add(`image_${item.alt}_${images.length}`);
          }
        }
        for (const list of divList) {
          let images = await list?.querySelectorAll("img");
          for (const item of images) {
            item?.classList.add(`image_${item.alt}_${images.length}`);
          }
        }
      }
      return;
    };
  });

  useEffect(() => {
    setIsLikeClick(localStorage.getItem(`liked${contents?.id}`));
  }, [contents?.id]);
  useEffect(() => {
    getData(params?.id).then((res: BlogContent) => setContents(res));
  }, [getData, params?.id]);
  useEffect(() => {
    setTagList(contents?.tag?.split(","));
  }, [contents?.tag]);

  return (
    <>
      <ScrollTop contents={contents} />
      <Head
        title={`MondayOFF - ${contents?.title}`}
        url={`https://blog.mondayoff.me/${contents?.slug}/${contents?.postId}`}
        description={contents?.title}
        imageUrl={contents?.thumbnail}
      />
      <article
        className={styles.container}
        onContextMenu={(e) => e.preventDefault()}
      >
        <Thumbnail
          title={contents?.title}
          backgroundColor={contents?.backgroundColor}
          category={contents?.category}
          writer={contents?.writer}
          job={contents?.job}
          date={contents?.date}
          thumbnail={contents?.thumbnail}
        />

        <div
          className={styles.contents}
          data-content={t("transLn") === "en" ? "" : "korean"}
          dangerouslySetInnerHTML={{ __html: contents?.contents }}
        />
        <div className={styles.tagList}>
          <p>Tags</p>
          <ul>
            {tagList?.map((item, key) => (
              <li key={key} onClick={() => onTagClick(item)}>
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.icon_box}>
          <div onClick={onShareClick} className={styles.share_area}>
            {showPopup && (
              <div className={styles.copyPopupMessage}>
                <LinkIcon />
                <p>Link has been copied!</p>
              </div>
            )}
            <ShareIcon className={styles.shareIcon} />
          </div>
          <div onClick={onLikeClick} className={styles.like_area}>
            <LikeIcon
              className={
                isLikeCLick === "true"
                  ? styles.active_like
                  : styles.inactive_like
              }
            />
          </div>
        </div>
        <Banner />
        <List category={contents?.category} id={contents?.id} />
      </article>
    </>
  );
}
