import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../db/cookie";
import styles from "./NotMatching.module.scss";
import tricleImg from "../../../image/trickle.png";
export default function NotMatching() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const onClick = () => {
    navigate("/");
  };
  useEffect(() => {
    i18n.changeLanguage(
      getCookie("lang")?.ln?.toLowerCase() === "kr" ? "ko" : "en"
    );
  }, [i18n]);
  return (
    <article className={styles.container}>
      <img src={tricleImg} alt="trickle" />
      <span>
        <p>{t("noResult")}</p>
      </span>
      <button onClick={onClick}>{t("searchHome")}</button>
    </article>
  );
}
