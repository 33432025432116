import React from "react";
import styles from "./Banner.module.scss";
import jellyMan from "../../image/banner/jellyMan.png";
export default function Banner() {
  return (
    <section className={styles.container}>
      <img src={jellyMan} alt="jellyMan" />
      <div>
        <h2>Publish with MondayOFF</h2>
        <p>Make your next big hit with flexible KPIs!</p>
      </div>
      <button>Submit Your Game</button>
    </section>
  );
}
