import React from "react";
import { useEffect } from "react";
import { BlogContent } from "../../Context/BlogContext";

export default function ScrollTop({ contents }: { contents: BlogContent }) {
  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, [contents]);
  return null;
}
