import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Head from "../../Common/Head/Head";
import { BlogList, useBlogs } from "../../Context/BlogContext";
import { useGA4 } from "../../Context/GA4Context";
import styles from "./TagSearch.module.scss";

export default function TagSearch() {
  const tag = useLocation().state;
  const { onEventClick } = useGA4();
  const navigate = useNavigate();
  const onTagClick = (tag: string) => {
    onEventClick(tag, "tag_click");
    navigate(`/tag?q=${tag}`, { state: tag });
  };
  const { blogList } = useBlogs();
  const [tagFilterList, setTagFilterList] = useState<BlogList>([] as BlogList);
  useEffect(() => {
    for (const item of blogList) {
      item.tag.includes(tag) && setTagFilterList((prev) => [...prev, item]);
    }
    return () => setTagFilterList([]);
  }, [blogList, tag]);

  return (
    <div className={styles.container}>
      <Head
        title={`MondayOFF - ${tag}`}
        url="http://localhost:3001"
        description="MondayOFF - Blog"
      />
      <section className={styles.tag}>{tag}</section>
      <section className={styles.list}>
        <div className={styles.article_count}>
          <p>{tagFilterList.length}</p>
          <p>Articles</p>
        </div>
        <ul>
          {tagFilterList.map((item) => (
            <div className={styles.search_item}>
              <span>
                <Link
                  to={`/${item.slug}/${item.id}`}
                  key={item.id}
                  onClick={() => {
                    onEventClick(item.title, "tag_search_list_click");
                  }}
                >
                  <p>{item.category}</p>
                  <h2>{item.title}</h2>
                  <p>
                    {item.writer} • {item.job}
                  </p>
                  <p>{item.date}</p>
                </Link>

                <ul>
                  {item.tag.split(",").map((tagItem) => (
                    <li key={Math.random()} onClick={() => onTagClick(tagItem)}>
                      {tagItem}
                    </li>
                  ))}
                </ul>
              </span>
              <Link
                to={`/${item.slug}/${item.postId}`}
                onClick={() => {
                  onEventClick(item.title, "tag_search_list_click");
                }}
              >
                <div
                  className={styles.thumbnail}
                  style={{ backgroundImage: `url(${item.thumbnail})` }}
                />
              </Link>
            </div>
          ))}
        </ul>
      </section>
    </div>
  );
}

React.memo(TagSearch);
