"use client";

import axios from "axios";
import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGA4 } from "./GA4Context";
import { useSearch } from "./SearchContext";

interface ContextProps {
  getBlog: any;
  getData: any;
  blogList: BlogList;
  filterBlog: BlogList;
  selectCategory: SelectCategory;
  setSelectCategory: (item: SelectCategory) => void;
  onSearchClick: any;
  onKeyPress: any;
  onSearchTextChange: any;
  searchValue: string;
  clickLike: (id: number) => Promise<void>;
  subtracylickLike: (id: number) => Promise<void>;
  shareClick: (id: number) => Promise<void>;
  setSearchValue: (value: string) => void;
}
type SelectCategory = {
  id: number;
  category: string;
};
export type BlogList = Array<{
  id: number;
  userId: number;
  thumbnail: string;
  writer: string;
  job: string;
  date: string;
  category: string;
  slug: string;
  title: string;
  contents: string;
  backgroundColor: string;
  like: number;
  share: number;
  tag: string;
  postId: number;
}>;
export type BlogContent = {
  id: number;
  userId: number;
  thumbnail: string;
  writer: string;
  job: string;
  date: string;
  category: string;
  title: string;
  slug: string;
  contents: string;
  backgroundColor: string;
  like: number;
  share: number;
  tag: string;
  postId: number;
};
const BlogContext = createContext({} as ContextProps);

export default function BlogProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { setIsSearchClick } = useSearch();
  const { onEventClick } = useGA4();
  const [blogList, setBlogList] = useState<BlogList>([] as BlogList);
  const [filterBlog, setFilterBlog] = useState<BlogList>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchList, setSearchList] = useState<BlogList>([] as BlogList);
  const onSearchTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const { value } = e.target;
      setSearchValue(value);
      setSearchList(() =>
        blogList.filter((item) => {
          if (!value.length) {
            return item;
          } else {
            return (
              item.title.toLowerCase().includes(value.toLowerCase()) ||
              item.contents.toLowerCase().includes(value.toLowerCase())
            );
          }
        })
      );
    },
    [blogList]
  );

  const onKeyPress = useCallback(
    (e: any) => {
      if (!searchValue.length && e.keyCode === 13) {
        return;
      }
      if (e.keyCode === 13) {
        setIsSearchClick(false);
        onEventClick(searchValue, "search");
        return navigation(`/search?q=${searchValue}`, {
          state: { blogs: searchList, searchValue },
        });
      }
    },
    [navigation, onEventClick, searchList, searchValue, setIsSearchClick]
  );
  const onSearchClick = useCallback(() => {
    if (!searchValue.length) {
      return;
    } else {
      setIsSearchClick(false);
      onEventClick(searchValue, "search");
      return navigation(`/search?q=${searchValue}`, {
        state: { blogs: searchList, searchValue },
      });
    }
  }, [navigation, onEventClick, searchList, searchValue, setIsSearchClick]);
  const [selectCategory, setSelectCategory] = useState<{
    id: number;
    category: string;
  }>({ id: 1, category: "ALL" });
  const getBlog = useCallback(async () => {
    let data = await axios(`${BASE}/blog/${t("transLn")}`, {
      method: "GET",
    })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
    setBlogList(data);
    switch (selectCategory.id) {
      case 1:
        return setFilterBlog(data);
      case 2:
        return setFilterBlog(
          data.filter((item: any) => item.category === selectCategory.category)
        );
      case 3:
        return setFilterBlog(
          data.filter((item: any) => item.category === selectCategory.category)
        );
      case 4:
        return setFilterBlog(
          data.filter((item: any) => item.category === selectCategory.category)
        );
      default:
        return setFilterBlog(data);
    }
  }, [BASE, selectCategory.category, selectCategory.id, t]);

  const getData = useCallback(
    async (id: string) => {
      let data = await axios(`${BASE}/blog/${t("transLn")}/${id}`, {
        method: "GET",
      })
        .then((res) => {
          return res.data;
        })
        .catch((e) => e);

      return data;
    },
    [BASE, t]
  );

  const clickLike = useCallback(
    async (id: number) => {
      onEventClick("like", "click");
      await axios(`${BASE}/blog/like/${id}`, {
        method: "POST",
      });
    },
    [BASE]
  );
  const subtracylickLike = useCallback(
    async (id: number) => {
      await axios(`${BASE}/blog/like/subtract/${id}`, {
        method: "POST",
      });
    },
    [BASE]
  );
  const shareClick = useCallback(
    async (id: number) => {
      onEventClick("share", "click");
      await axios(`${BASE}/blog/share/${id}`, {
        method: "POST",
      });
    },
    [BASE]
  );
  useEffect(() => {
    getBlog();
  }, [getBlog]);
  const context = useMemo(
    () => ({
      getBlog,
      getData,
      blogList,
      selectCategory,
      setSelectCategory,
      filterBlog,
      onSearchClick,
      onKeyPress,
      onSearchTextChange,
      searchValue,
      clickLike,
      subtracylickLike,
      shareClick,
      setSearchValue,
    }),
    [
      getBlog,
      getData,
      blogList,
      selectCategory,
      filterBlog,
      onSearchClick,
      onKeyPress,
      onSearchTextChange,
      searchValue,
      clickLike,
      subtracylickLike,
      shareClick,
      setSearchValue,
    ]
  );

  return (
    <BlogContext.Provider value={context}>{children}</BlogContext.Provider>
  );
}

export const useBlogs = () => useContext(BlogContext);
