import React from "react";
import ReactDOM from "react-dom/client";
import "./index.module.scss";
import "./i18n/i18n";
import App from "./App";
import SearchProvider from "./Context/SearchContext";
import { BrowserRouter } from "react-router-dom";
import Search from "./Components/Search/Search";
import BlogProvider from "./Context/BlogContext";
import GA4Provider from "./Context/GA4Context";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <GA4Provider>
      <QueryClientProvider client={queryClient}>
        <SearchProvider>
          <BlogProvider>
            <App />
            <Search />
          </BlogProvider>
        </SearchProvider>
      </QueryClientProvider>
    </GA4Provider>
  </BrowserRouter>
);
