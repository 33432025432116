import styles from "./App.module.scss";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Router from "./Router/Router";

function App() {
  return (
    <>
      <Header />
      <div id={styles.wrap}>
        <Router />
      </div>
      <Footer />
    </>
  );
}

export default App;
