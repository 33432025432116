"use client";

import React, { useState, useContext, useMemo, useEffect } from "react";

import { createContext } from "react";
interface ContextProps {
  isSearchClick: boolean;
  setIsSearchClick: (bool: boolean) => void;
}

const SearchContext = createContext({} as ContextProps);

export default function SearchProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isSearchClick, setIsSearchClick] = useState<boolean>(false);
  const context = useMemo(
    () => ({
      isSearchClick,
      setIsSearchClick,
    }),
    [isSearchClick]
  );
  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body");
    if (isSearchClick) {
      bodyElement[0].style.overflowY = "hidden";
    } else {
      bodyElement[0].style.overflowY = "visible";
    }
  }, [isSearchClick]);
  return (
    <SearchContext.Provider value={context}>{children}</SearchContext.Provider>
  );
}

export const useSearch = () => useContext(SearchContext);
