import React from "react";
import styles from "./Slides.module.scss";
import { Slide } from "react-slideshow-image";
import { t } from "i18next";
import "react-slideshow-image/dist/styles.css";
import arrow from "../../../image/contents/slides/arrow.png";

import { useBlogs } from "../../../Context/BlogContext";
import { Link } from "react-router-dom";
import { useGA4 } from "../../../Context/GA4Context";

export default function Slides() {
  const { blogList } = useBlogs();
  const { onEventClick } = useGA4();
  const properties = {
    prevArrow: (
      <button className={styles.prev_arrow}>
        <img src={arrow} alt="prev_arrow" />
      </button>
    ),
    nextArrow: (
      <button className={styles.next_arrow}>
        <img src={arrow} alt="next_arrow" />
      </button>
    ),
  };

  return (
    <div className={styles.container}>
      {blogList.length && (
        <Slide
          {...properties}
          indicators={true}
          arrows={false}
          autoplay={true}
          duration={4000}
          transitionDuration={400}
          easing="ease"
        >
          {blogList.slice(0, 3).map((item) => (
            <Link
              to={{
                pathname: `/${item.slug}/${item.postId}`,
              }}
              state={{ contents: item }}
              key={item.id}
              className={styles.each_slide_effect}
              onClick={() => {
                onEventClick(item.title, "slide_click");
              }}
            >
              <div style={{ backgroundColor: item.backgroundColor }}>
                <div className={styles.title_area}>
                  <p className={styles.tag}>{item.category}</p>
                  <h2
                    className={styles.article_title}
                    data-content={t("transLn") === "ko" && "korean"}
                  >
                    {item.title}
                  </h2>
                  <p className={styles.name}>
                    {item.writer} • {item.job}
                  </p>
                </div>
                <div
                  className={styles.thumbnail}
                  style={{ backgroundImage: `url(${item.thumbnail})` }}
                />
              </div>
            </Link>
          ))}
        </Slide>
      )}
    </div>
  );
}

React.memo(Slides);
