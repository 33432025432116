import { t } from "i18next";
import styles from "./SearchList.module.scss";
import { BlogList } from "../../../Context/BlogContext";
import { Link, useNavigate } from "react-router-dom";
import { useGA4 } from "../../../Context/GA4Context";

export default function SearchList({ blogs }: { blogs: BlogList }) {
  const navigate = useNavigate();
  const { onEventClick } = useGA4();
  const onTagClick = (tag: string) => {
    onEventClick(tag, "search_list_tag_click");
    navigate(`/tag?q=${tag}`, { state: tag });
  };
  return (
    <section className={styles.container}>
      <div className={styles.list}>
        <div className={styles.article_length}>
          <p>{blogs.length}</p> <p>Articles</p>
        </div>
        <ul>
          {blogs.map((item) => (
            <div className={styles.search_item} key={item.id}>
              <span>
                <Link
                  to={`/${item.slug}/${item.postId}`}
                  onClick={() => onEventClick(item.title, "search_list_click")}
                >
                  <p>{item.category}</p>
                  <h2 data-content={t("transLn") === "ko" && "korean"}>
                    {item.title}
                  </h2>
                  <p>
                    {item.writer} • {item.job}
                  </p>
                  <p>{item.date}</p>
                </Link>

                <ul>
                  {item.tag.split(",").map((tagItem, idx) => (
                    <li key={idx} onClick={() => onTagClick(tagItem)}>
                      {tagItem}
                    </li>
                  ))}
                </ul>
              </span>
              <Link
                to={`/${item.slug}/${item.postId}`}
                onClick={() => onEventClick(item.title, "search_list_click")}
              >
                <div
                  className={styles.thumbnail}
                  style={{ backgroundImage: `url(${item.thumbnail})` }}
                />
              </Link>
            </div>
          ))}
        </ul>
      </div>
    </section>
  );
}
