import React, { useState } from "react";
import styles from "./Header.module.scss";
import logo from "../../image/MondayOFF_Signature.png";
import searchIcon from "../../image/blog_search.png";
import { useSearch } from "../../Context/SearchContext";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { getCookie, setCookie } from "../../db/cookie";
import { useGA4 } from "../../Context/GA4Context";

const lang: { id: number; ln: string } = getCookie("lang") ?? {
  id: 1,
  ln: "EN",
};
export default function Header() {
  const [isSelectLang, setIsSelectLang] = useState(lang);
  const { setIsSearchClick } = useSearch();
  const { onEventClick } = useGA4();
  const { t, i18n } = useTranslation();
  const onChangeLang = async (item: { id: number; ln: string }) => {
    setCookie("lang", item);
    i18n.changeLanguage(getCookie("lang")?.id === 2 ? "ko" : "en");
  };

  // useEffect(() => {
  //   i18n.changeLanguage(getCookie("lang")?.id === 2 ? "ko" : "en");
  // }, [i18n]);
  const [isView, setIsView] = useState<boolean>(false);
  const [langList] = useState<Array<{ id: number; ln: string }>>([
    { id: 1, ln: "EN" },
    { id: 2, ln: "KR" },
  ]);

  const selectLang = (id: Number) => {
    return isSelectLang.id === id;
  };

  return (
    <header className={styles.container}>
      <Link
        to="/"
        className={styles.home_link}
        onClick={() => {
          onEventClick("Home", "logo_click");
        }}
      >
        <img src={logo} alt="MondayOFF-logo" className={styles.logo} />
        <p>{t("Blog")}</p>
      </Link>
      <div className={styles.menuList}>
        <Link
          to="https://www.mondayoff.me"
          target="_blank"
          onClick={() => {
            onEventClick("MondayOFF", "menu_click");
          }}
        >
          {t("MondayOFF")}
        </Link>
        <Link
          to="https://careers.mondayoff.me"
          target="_blank"
          onClick={() => {
            onEventClick("careers", "menu_click");
          }}
        >
          {t("Careers")}
        </Link>
        <Link
          to="https://dashboard.mondayoff.me"
          target="_blank"
          onClick={() => {
            onEventClick("dashboard", "menu_click");
          }}
        >
          {t("Dashboard")}
        </Link>
        <div className={styles.translation_lang}>
          {isSelectLang.ln}
          <ul className={styles.select_lang}>
            {langList.map((item) => (
              <li
                key={item.id}
                className={selectLang(item.id) ? styles.active : ""}
                onClick={() => {
                  setIsSelectLang(item);
                  setIsView(false);
                  onChangeLang(item);
                  onEventClick(item.ln, "languege_click");
                }}
              >
                {item.ln}
              </li>
            ))}
          </ul>
        </div>

        <img
          src={searchIcon}
          alt="icon"
          className={styles.search_icon}
          onClick={() => {
            setIsSearchClick(true);
            onEventClick("search", "search_click");
            window.scrollTo(0, 0);
          }}
        />
      </div>
    </header>
  );
}
React.memo(Header);
