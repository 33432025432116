import React from "react";
import { Route, Routes } from "react-router-dom";
import BlogProvider from "../Context/BlogContext";

import Article from "../Pages/Article/Article";
import Home from "../Pages/Home/Home";
import NotFound from "../Pages/NotFound/NotFound";
import SearchResult from "../Pages/SearchResult/SearchResult";
import TagSearch from "../Pages/TagSearch/TagSearch";

export default function Router() {
  return (
    <BlogProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:title/:id" element={<Article />} />
        <Route path="/search" element={<SearchResult />} />
        <Route path="/tag" element={<TagSearch />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BlogProvider>
  );
}
