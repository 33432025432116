import React from "react";
import { useLocation } from "react-router-dom";
import Head from "../../Common/Head/Head";
import NotMatching from "../../Components/SearchResult/NotMatching/NotMatching";
import SearchList from "../../Components/SearchResult/SearchList/SearchList";
import SearchSection from "../../Components/SearchResult/SearchSection/SearchSection";
import styles from "./SearchResult.module.scss";
export default function SearchResult() {
  const { blogs } = useLocation().state;

  return (
    <section className={styles.container}>
      <Head
        title="MondayOFF - Search"
        url="http://localhost:3002"
        description="MondayOFF - Blog"
      />
      <SearchSection />
      {blogs.length ? <SearchList blogs={blogs} /> : <NotMatching />}
    </section>
  );
}
React.memo(SearchResult);
