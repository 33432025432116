import React from "react";
import ArticleList from "./ArticleList/ArticleList";

import styles from "./Contents.module.scss";
import Slides from "./Slides/Slides";

export default function Contents() {
  return (
    <section
      className={styles.container}
      onContextMenu={(e) => e.preventDefault()}
    >
      <Slides />
      <div className={styles.article_list}>
        <ArticleList />
      </div>
    </section>
  );
}
