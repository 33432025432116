import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Head from "../../Common/Head/Head";
import styles from "./NotFound.module.scss";
export default function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onHomeClick = () => {
    return navigate("/");
  };
  return (
    <>
      <Head
        title="MondayOFF - Blog"
        url="http://localhost:3002"
        description="MondayOFF - blog"
      />
      <article className={styles.container}>
        <span>
          <p data-content="mobile">SORRY!</p>
          <p data-content="mobile">Page Not Found</p>
        </span>
        <div>
          <p data-content="web">SORRY!</p>
          <p data-content="web">Page Not Found</p>
        </div>
        <button onClick={onHomeClick}>{t("notFoundHome")}</button>
      </article>
    </>
  );
}
//notFoundHome
