"use client";
import { BlogList, useBlogs } from "../../../Context/BlogContext";

import React, { useEffect, useState } from "react";
import styles from "./List.module.scss";
import { Link } from "react-router-dom";
import { useGA4 } from "../../../Context/GA4Context";

export default function List({
  category,
  id,
}: {
  category: string;
  id: number;
}) {
  const { blogList } = useBlogs();
  const { onEventClick } = useGA4();
  const [filterList, setFilterList] = useState<BlogList>([] as BlogList);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    setFilterList(
      blogList?.filter((item) => item.category === category && item.id !== id)
    );
  }, [blogList, category, id]);
  const windowSize = () => {
    setWindowWidth((prev) => (prev = window.innerWidth));
  };
  useEffect(() => {
    window.onresize = windowSize;
  });
  const listCount = () => {
    if (windowWidth > 481 && windowWidth < 961) return 2;
    else {
      return 3;
    }
  };

  return (
    <section className={styles.container}>
      <p>You might also like</p>
      <div className={styles.cardContainer}>
        {filterList.slice(0, listCount()).map((item) => (
          <Link
            to={{
              pathname: `/${item.slug}/${item.postId}`,
            }}
            key={item.id}
            className={styles.card}
            onClick={() => {
              onEventClick(item.title, "list_click_in_article");
            }}
          >
            <div style={{ backgroundImage: `url("${item.thumbnail}")` }} />
            <div>
              <h2>{item.title}</h2>
              <p>{item.date}</p>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
}
React.memo(List);
