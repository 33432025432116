import React, { MutableRefObject, RefObject } from "react";
import { Link } from "react-router-dom";
import { BlogContent, BlogList } from "../../../../Context/BlogContext";
import { t } from "i18next";
import styles from "./Articles.module.scss";
import { useGA4 } from "../../../../Context/GA4Context";

export default function Articles({
  articleCount,
  blogList,
  lastRef,
}: {
  articleCount: number;
  blogList: BlogList;
  lastRef: any;
}) {
  const { onEventClick } = useGA4();
  return (
    <ul className={styles.article_box}>
      {blogList?.slice(0, articleCount).map((item: BlogContent, i: number) => {
        const backgroundImg = { backgroundImage: `url(${item.thumbnail})` };
        const isLastElement = blogList?.slice(0, articleCount).length === i + 1;
        return (
          <Link
            to={{
              pathname: `/${item.slug}/${item.postId}`,
            }}
            state={{ contents: item }}
            key={item.id}
            ref={isLastElement ? lastRef : undefined}
            onClick={() => {
              onEventClick(item.title, "list_click");
            }}
          >
            <li>
              <div className={styles.image_area} style={backgroundImg}></div>

              <h3 data-content={t("transLn") === "ko" && "korean"}>
                {item.title}
              </h3>
              <p>{item.date}</p>
            </li>
          </Link>
        );
      })}
    </ul>
  );
}
