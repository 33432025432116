import Head from "../../Common/Head/Head";
import Contents from "../../Components/Contents/Contents";
import BlogProvider from "../../Context/BlogContext";
import mondayoffImg from "../../image/mondayoff_logo_profile.jpg";
export default function Home() {
  return (
    <>
      <Head
        title="MondayOFF - Blog"
        url="https://blog.mondayoff.me"
        description="MondayOFF - Blog"
        imageUrl={mondayoffImg}
      />
      <BlogProvider>
        <Contents />
      </BlogProvider>
    </>
  );
}
