import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

interface ContextProps {
  onEventClick: (eventCategory: string, eventAction: string) => void;
}

const GA4Context = createContext({} as ContextProps);

export default function GA4Provider({
  children,
}: {
  children: React.ReactNode;
}) {
  const GA4ID: any = process.env.REACT_APP_GA4_ID;
  const location = useLocation();
  const [initialized, setInitialized] = useState<boolean>(false);
  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(GA4ID);
      setInitialized(true);
    }
  }, [GA4ID]);
  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send("pageview");
    }
  }, [initialized, location]);

  const onEventClick = useCallback(
    (eventCategory: string, eventAction: string) => {
      if (initialized) {
        ReactGA.event({
          category: eventCategory,
          action: eventAction,
        });
      }
    },
    [initialized]
  );
  const context = useMemo(() => ({ onEventClick }), [onEventClick]);

  return <GA4Context.Provider value={context}>{children}</GA4Context.Provider>;
}

export const useGA4 = () => useContext(GA4Context);
