import React from "react";
import styles from "./Thumbnail.module.scss";
import { useTranslation } from "react-i18next";
export default function Thumbnail({
  title,
  backgroundColor,
  category,
  writer,
  job,
  date,
  thumbnail,
}: {
  title: string;
  backgroundColor: string;
  category: string;
  writer: string;
  job: string;
  date: string;
  thumbnail: string;
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.container} style={{ backgroundColor }}>
      <div
        className={styles.title_area}
        data-content={t("transLn") === "en" ? "" : "korean"}
      >
        <p>{category}</p>
        <h2>{title}</h2>
        <p>
          {writer} • {job}
        </p>
        <p>{date}</p>
      </div>
      <div className={styles.thumbnail_area}>
        <div
          className={styles.thumbnail}
          style={{ backgroundImage: `url(${thumbnail})` }}
        />
      </div>
    </div>
  );
}
