import React, { useEffect } from "react";

import styles from "./Footer.module.scss";
import logo from "../../image/MondayOFF_Signature.png";
import { getCookie } from "../../db/cookie";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Footer() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(
      getCookie("lang")?.ln?.toLowerCase() === "kr" ? "ko" : "en"
    );
  }, [i18n]);
  return (
    <footer className={styles.container}>
      <img src={logo} alt="logo" className={styles.logo} />
      <ul className={styles.menu_list}>
        <li>
          <Link to="https://www.mondayoff.me" target="_blank">
            {t("Website")}
          </Link>
        </li>
        <li>
          <Link to="https://careers.mondayoff.me/" target="_blank">
            {t("Careers")}
          </Link>
        </li>
        <li>
          <Link to="https://dashboard.mondayoff.me" target="_blank">
            {t("Dashboard")}
          </Link>
        </li>
      </ul>
      <div className={styles.privacy_area}>
        <p>©2023 MondayOFF</p>
        <p>|</p>
        <Link to="https://www.mondayoff.me/privacyPolicy" target="_blank">
          Privacy
        </Link>
        <p>|</p>
        <Link to="mailto:publishing@mondayoff.me" target="_blank">
          Contact us
        </Link>
      </div>
    </footer>
  );
}
React.memo(Footer);
