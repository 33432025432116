"use client";
import React, { useState, useEffect, useRef } from "react";
import CategoryList from "./CategoryList/CategoryList";
import styles from "./ArticleList.module.scss";
import Articles from "./Articles/Articles";

import { useBlogs } from "../../../Context/BlogContext";

export default function ArticleList() {
  const [innerWidth, setInnerWidth] = useState(window?.innerWidth);
  const [articleCount, setArticleCount] = useState(0);

  const { filterBlog } = useBlogs();
  const lastRef = useRef<any>(null);
  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    if (innerWidth > 960) setArticleCount(9);
    if (innerWidth > 768 && innerWidth < 961) setArticleCount(4);
    if (innerWidth > 479 && innerWidth < 769) setArticleCount(6);
    if (innerWidth < 481) setArticleCount(3);
  }, [innerWidth]);
  // const onRoadClick = () => {
  //   if (innerWidth > 960) setArticleCount((prev) => prev + 9);
  //   if (innerWidth > 768 && innerWidth < 961)
  //     setArticleCount((prev) => prev + 4);
  //   if (innerWidth > 479 && innerWidth < 769)
  //     setArticleCount((prev) => prev + 6);
  //   if (innerWidth < 481) setArticleCount((prev) => prev + 3);
  // };
  useEffect(() => {
    if (!lastRef.current) return;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (innerWidth > 960) {
          filterBlog.length > articleCount &&
            setArticleCount((prev) => prev + 9);
        }
        if (innerWidth > 768 && innerWidth < 961) {
          filterBlog.length > articleCount &&
            setArticleCount((prev) => prev + 4);
        }
        if (innerWidth > 479 && innerWidth < 769) {
          filterBlog.length > articleCount &&
            setArticleCount((prev) => prev + 6);
        }
        if (innerWidth < 481) {
          filterBlog.length > articleCount &&
            setArticleCount((prev) => prev + 3);
        }
      }
    });
    observer.observe(lastRef.current);
    return () => observer.disconnect();
  });

  return (
    <section className={styles.container}>
      <CategoryList />
      <Articles
        articleCount={articleCount}
        blogList={filterBlog}
        lastRef={lastRef}
      />

      {/* {articleCount < filterBlog.length && (
        <button className={styles.paging_btn} onClick={onRoadClick}>
          Load More
        </button>
      )} */}
    </section>
  );
}
