import React, { useEffect } from "react";
import styles from "./SearchSection.module.scss";
import { getCookie } from "../../../db/cookie";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../image/search_icon.svg";
import { useBlogs } from "../../../Context/BlogContext";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

export default function SearchSection() {
  const {
    onSearchClick,
    onKeyPress,
    onSearchTextChange,
    setSearchValue,
    searchValue,
  } = useBlogs();
  const searchRef = useRef<HTMLInputElement>(null);
  const { state } = useLocation();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getCookie("lang")?.id === 2 ? "ko" : "en");
  }, [i18n]);
  useEffect(() => {
    setSearchValue(state.searchValue);
  }, [setSearchValue, state.searchValue]);
  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <input
          ref={searchRef}
          type="text"
          placeholder={t("search")}
          onChange={onSearchTextChange}
          onKeyDown={onKeyPress}
          value={searchValue}
        />
        <img src={SearchIcon} alt="glasses" onClick={onSearchClick} />
      </div>
    </div>
  );
}
React.memo(SearchSection);
