import React, { useEffect, useRef } from "react";
import { getCookie } from "../../db/cookie";
import { useTranslation } from "react-i18next";
import { useSearch } from "../../Context/SearchContext";
import styles from "./Search.module.scss";
import closeIcon from "../../image/searchClose.png";
import SearchIcon from "../../image/search_icon.svg";
import { useBlogs } from "../../Context/BlogContext";
import { useLocation } from "react-router-dom";

export default function Search() {
  const { isSearchClick, setIsSearchClick } = useSearch();
  const searchRef = useRef<HTMLInputElement>(null);

  const { onSearchClick, onKeyPress, onSearchTextChange } = useBlogs();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getCookie("lang")?.id === 2 ? "ko" : "en");
  }, [i18n]);

  return (
    <>
      {isSearchClick && (
        <section className={styles.container}>
          <div>
            <img
              src={closeIcon}
              alt="closeIcon"
              className={styles.closeIcon}
              onClick={() => setIsSearchClick(false)}
            />
            <div className={styles.search_area}>
              <p>Search</p>
              <div className={styles.search_box}>
                <input
                  ref={searchRef}
                  type="text"
                  placeholder={t("search")}
                  onChange={onSearchTextChange}
                  onKeyDown={onKeyPress}
                />
                <img
                  src={SearchIcon}
                  alt="icon"
                  className={styles.searchIcon}
                  onClick={onSearchClick}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
React.memo(Search);
