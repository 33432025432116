import { useBlogs } from "../../../../Context/BlogContext";
import React from "react";
import { useState } from "react";
import styles from "./CategoryList.module.scss";
export default function CategoryList() {
  const { selectCategory, setSelectCategory } = useBlogs();

  const list = [
    {
      id: 1,
      category: "ALL",
    },
    { id: 2, category: "NEWS" },
    { id: 3, category: "INSIGHT" },
    { id: 4, category: "CULTURE" },
  ];
  const isSelect = (id: number) => {
    return selectCategory.id === id;
  };

  return (
    <ul className={styles.container}>
      {list.map((item) => (
        <li
          className={styles.list_item}
          key={item.id}
          data-content={isSelect(item.id) ? "active" : ""}
          onClick={() => setSelectCategory(item)}
        >
          {item.category}
        </li>
      ))}
    </ul>
  );
}
